import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import ColumnAlternating from '../components/columnAlternatingStyled'
import { MDBContainer } from 'mdbreact'
import moment from 'moment'

const Events = ({ data }) => {
  const events = data.allContentfulEvent.edges
  const heroImage = data.heroImage
  const conference = data.conference
  const curDate = moment(new Date()).format('YYYY-MM-DD')
  const futureEvents = events.filter(event => moment(event.node.date).format('YYYY-MM-DD') >= curDate).length > 0 ? true : false
 
  
  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={heroImage.childImageSharp.gatsbyImageData}
          alt="person in audience sitting holding pen and notepad"
          title="Events"
          subtitle="Join us at industry conferences, webinars and exhibitions."
          type="events"
        /> 

        <section className="bg-white">
          <MDBContainer>
            {futureEvents &&
              <>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                  Upcoming events
                </h2>

                {events.filter(event => moment(event.node.date).format('YYYY-MM-DD') >= curDate).sort((a,b) => moment(a.node.date).format('YYYY-MM-DD') == moment(b.node.date).format('YYYY-MM-DD') ? 0 : moment(a.node.date).format('YYYY-MM-DD') < moment(b.node.date).format('YYYY-MM-DD') ? -1 : 1).map(({ node }, index) => (
                  <ColumnAlternating
                    key={index}
                    title={`<span class="font-alt font-w-400 letter-spacing-1 mb-3 text-large">${moment(node.date).format('MMMM DD, YYYY - HH:mm')}</span> <br /><br /> ${node.title}`}
                    subtitle={node.subtitle}
                    image={node.heroSmall ? node.heroSmall.localFile.childImageSharp.gatsbyImageData : conference.childImageSharp.gatsbyImageData}
                    alt={node.title}
                    titleClass="font-alt font-w-700 letter-spacing-1 mb-3 title-small"
                    subtitleClass="font-w-400 letter-spacing-1 text-medium"
                    link={`/events/` + node.slug + `/`}
                    textCol={'7'}
                    imageCol={'5'}
                    colour="ficon-blue"
                  />
                ))}
              </>
            }

            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
              Past events
            </h2>
            {events.filter(event => moment(event.node.date).format('YYYY-MM-DD') < curDate).map(({ node }, index) => (
              <ColumnAlternating
                key={index}
                title={`<span class="font-alt font-w-400 letter-spacing-1 mb-3 text-large">${moment(node.date).format('MMMM DD, YYYY - HH:mm')}</span> <br /><br /> ${node.title}`}
                subtitle={node.subtitle}
                image={node.heroSmall ? node.heroSmall.localFile.childImageSharp.gatsbyImageData : conference.childImageSharp.gatsbyImageData}
                alt={node.title}
                titleClass="font-alt font-w-700 letter-spacing-1 mb-3 title-small"
                subtitleClass="font-w-400 letter-spacing-1 text-medium"
                link={`/events/` + node.slug + `/`}
                textCol={'7'}
                imageCol={'5'}
                colour="ficon-blue"
              />
            ))}
          </MDBContainer>
        </section>
      </Layout>
    </div>
  )
}
export default Events

export function Head({ data, location }) {
  const heroImage = data.heroImage
  return (
    <SEO
      title="UNICOM Systems, Inc. events"
      description="UNICOM Systems regularly attends, sponsors and presents at relevant industry and corporate events, join us to find out more."
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {
    allContentfulEvent(
      filter: {publishTo: {elemMatch: {url: {eq: "https://www.unicomsi.com"}}}, 
      date: {gte: "2022-01-01" }}
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          slug
          title
          subtitle
          date
          heroSmall {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 720, quality: 90)
              }
            }
          }
        }
      }
    }
    conference: file(name: { eq: "conference" }) {
      childImageSharp {
        gatsbyImageData(width: 960, quality: 90)
      }
    }
    heroImage: file(name: { eq: "events-default" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`